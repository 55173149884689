// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// https://srs-test.yathisolutions.com/
// https://samaroham.jeeyargurukulam.in
// https://test.jeeyargurukulam.in
export const environment = {
  production: false,
  appurl: 'https://test.jeeyargurukulam.in',
  roles: {
    admin: {
      home: '/tabs/home'
    },
    user: {
      home: '/users'
    }
  },
  languages: [{
    title: 'English',
    value: 'en'
  }, {
    title: 'తెలుగు',
    value: 'te'
  },
  {
    title: 'हिंदी',
    value: 'hi'
  }],
  Channel: 1,
  Country: 1,
  CurrencySymbol: "₹",
  CurrencySelected: "INR",
  transactions: [
    { id: 1, name: 'cash', title: 'cash', icon: '../../assets/icon/cash.svg' },
    { id: 2, name: 'cheque', title: 'cheque', icon: '../../assets/icon/cheque.svg' },
    { id: 3, name: 'QR', title: 'QR', icon: '../../assets/icon/qr-code.svg' },
    { id: 4, name: 'Online', title: 'online payment', icon: '../../assets/icon/mobile-phone.svg' }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
